<template>
  <main>
    <router-view id="legal" />
    <app-hr />
    <app-cards desc="Learn more now" />
  </main>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";

import AppCards from "@/components/AppCards.vue";
import AppHr from "@/components/AppHr.vue";

const store = useStore();

onMounted(() => store.commit("updateHeader", false));
</script>
